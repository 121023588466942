/* The following class is needed to override some global styles in the PhoneInput that
cannot be updated through classes or className props. */
.iti-mobile .iti--container {
    z-index: 10000;
    top: 30px;
    bottom: 30px;
    left: 30px;
    right: 30px;
    position: fixed;
}
